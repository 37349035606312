import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="About Chapter 13 Bankruptcy | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1 className="text-primary">Chapter 13</h1>
				<hr />
				<p>Have you done everything to try and pay off your debt? Are you looking for a way to <Link to="/practice-areas/bankruptcy-law/foreclosure">stop foreclosure</Link>, garnishments, harassing phone calls and keep your assets? Chapter 13 bankruptcy may be right for you. By filing for Chapter 13 bankruptcy you can stop harassing phone calls from creditors, stop foreclosure, and enter a fair and manageable payment plan for your debt. At the Birmingham offices of Gina H. McDonald &amp; Associates, we can answer your questions about Chapter 13 bankruptcy today. If you have questions about debt relief, please contact Gina H. McDonald &amp; Associates at <a href="tel://12059823325">(205) 982-3325</a>.</p>
				<p>It is important to know what you can expect from the Chapter 13 bankruptcy process. After you file for Chapter 13 bankruptcy, your first debtor's court payment is due within 30 days and you will have to attend a meeting of the creditors in approximately six weeks later. This will be followed by one more mandatory court date, which is confirmation hearing with a judge. You will then be entered into a debt repayment plan that will last between three and five years. The payments you make will be based on the amount of debt, not how much you make. You will make payments every 30 days, for the length of the plan.</p>

				<h2>Advantages of Chapter 13 Bankruptcy</h2>
				<p>Chapter 13 bankruptcy is a great way to retain your assets while paying off your debt. By reorganizing your secure and unsecured debt into one payment plan, you can stop harassment from creditors, make payments you can afford, and keep your home. When the plan ends, your debt will be completely discharged in most cases.</p>

				<h2>Important Bankruptcy Information</h2>
				<p>There is certain information we must evaluate in order to help you file for bankruptcy. If you are considering Chapter 13 bankruptcy, you should start thinking about organizing this information. The sooner we get it, the sooner we can help you get the debt relief you deserve:</p>
				<ul>
					<li>Driver’s license, social security card</li>
					<li>Two years' filed tax returns (most currently filed return and last return filed prior to that)</li>
					<li>Six months of previous payroll and pay stubs</li>
					<li>Six months of bank statements prior to visiting our office, to include every bank/investment statement you have any interest in</li>
					<li>Tax assessment of your real property</li>
					<li>All of your bills – medical bills, credit card bills, etc.</li>
					<li>Judgments and pending lawsuits against you</li>
					<li>Any information of lawsuits you are pursuing</li>
					<li>Vehicle information and proof of insurance</li>
				</ul>
			</Col>
		</Row>
	</Container>
</Layout>